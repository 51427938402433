import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Text } from '@mantine/core';
import axios from 'axios';

const QueryTicketForm = () => {
    const [opened, setOpened] = useState(false);

  return (
    <div className="auth-container">
        <Button onClick={() => setOpened(true)}>Open modal</Button>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Example modal"
      >
        <Text>This is a Mantine modal</Text>
      </Modal>
    </div>
  );
};

export default QueryTicketForm;
