import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Home from './pages/Home';
import Login from './pages/Login';
import Robotics from './pages/Robotics';
import Register from './pages/Register';
import Profile from './pages/Profile';
import ExcelConversions from './pages/ExcelConversions';
import Schools from './pages/Schools';
import ContactUs from './pages/ContactUs';
import ConfigureRegionSchool from './pages/ConfigureRegionSchool';
import ViewSchools from './pages/ViewSchools';
import ManageClassesBatches from './pages/ManageClassesBatches';
import LessonPlanner from './pages/LessonPlanner';
import ManageUsers from './pages/ManageUsers';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import ManageWorkReport from './pages/ManageWorkReport';
import WorkReportRobotics from './pages/WorkReportRobotics';
import RoboticsDateRangeWorkReport from './pages/RoboticsDateRangeWorkReport';
import QueryTicketForm from './pages/QueryTicketForm';


import AuthProvider, { AuthContext } from './context/AuthContext';
import './App.css';

const PrivateRoute = ({ element: Component }) => {
  const { auth } = useContext(AuthContext);
  console.log("PrivateRoute auth check: ", auth); //debug
  return auth ? <Component /> : <Navigate to="/login" />;
};

const AppContent = () => {
  const location = useLocation();
  const noSidebarRoutes = ['/login', '/register','/forgot-password','/reset-password','/query-ticket-form'];
  const isNoSidebarRoute = noSidebarRoutes.includes(location.pathname);

  return (
    <div className={`App ${isNoSidebarRoute ? 'no-sidebar' : ''}`}>
      {!isNoSidebarRoute && <Sidebar />}
      <Routes>
        <Route path="/" element={<PrivateRoute element={Home} />} />
        <Route path="/robotics" element={<PrivateRoute element={Robotics} />} />
        <Route path="/profile" element={<PrivateRoute element={Profile} />} />
        <Route path="/excel-conversions" element={<PrivateRoute element={ExcelConversions} />} />
        <Route path="/schools" element={<PrivateRoute element={Schools} />} />
        <Route path="/contact-us" element={<PrivateRoute element={ContactUs} />} />
        <Route path="/configure-region-school" element={<PrivateRoute element={ConfigureRegionSchool} />} />
        <Route path="/view-schools" element={<PrivateRoute element={ViewSchools} />} />
        <Route path="/manage-classes-batches" element={<PrivateRoute element={ManageClassesBatches} />} />
        <Route path="/lesson-planner" element={<PrivateRoute element={LessonPlanner} />} />
        <Route path="/manage-users" element={<PrivateRoute element={ManageUsers} />} />
        <Route path="/report-view-work" element={<PrivateRoute element={ManageWorkReport} />} />
        <Route path="/work-report-robotics" element={<PrivateRoute element={WorkReportRobotics} />} />
        <Route path="/robotics-date-range-work-report" element={<PrivateRoute element={RoboticsDateRangeWorkReport} />} />
        <Route path="/query-ticket-form" element={<QueryTicketForm />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

export default App;
